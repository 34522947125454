import { SearchInProcessingClassDto } from '@/models/Dtos/searchInProcessingClassDto';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { DeleteEntityActionBuilder } from '../../EntityActions/DeleteEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { InProcessingClassTableData } from '../InProcessingClassTableData';

export class DeleteInProActionBuilder<
  TableDataType extends InProcessingClassTableData
> extends DeleteEntityActionBuilder<
  TableDataType,
  InProcessingClass,
  SearchInProcessingClassDto
> {
  public deleteString: 'delete' | 'archive' = 'delete';
  protected delete = async (
    baseStaffTableData: InProcessingClassTableData,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      InProcessingClass,
      SearchInProcessingClassDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'inProcessingClassModule/delete',
      baseStaffTableData.entityId
    );
  };
}
