import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import { InProcessingClassTableData } from './InProcessingClassTableData';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { SearchInProcessingClassDto } from '@/models/Dtos/searchInProcessingClassDto';
import { DeleteInProActionBuilder } from './EntityActions/DeleteInProActionBuilder';
import { EntityActionButtons } from '../models/EntityActionButtons';
import TableSearchService from '../models/TableSearch.service';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { ModalMounter } from '@/components/ModalMounter';
import IconLinkComponent from '@/components/IconLinkComponent.vue';

export const ReportInProcessingClassTableConfigBuilder: TableConfigBuilder<
  InProcessingClassTableData,
  InProcessingClass,
  never,
  SearchInProcessingClassDto
> = (
  tableSearchService: TableSearchService<
    InProcessingClassTableData,
    InProcessingClass,
    never,
    SearchInProcessingClassDto
  >,
  store: Store<RootState>
): ITableConfiguration<
  InProcessingClassTableData,
  InProcessingClass,
  never
> => {
  const tableButtons = new EntityActionButtons<
    InProcessingClassTableData,
    InProcessingClass,
    SearchInProcessingClassDto
  >(
    'inProcessingClass',
    store,
    tableSearchService,
    ModalMounter.instance.refs.yesNoModal
  );
  return {
    columnConfigurations: [
      {
        columnTitle: 'Start Date',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: InProcessingClassTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.startDate,
              linkTo: {
                name: Routes.INPRO_CLASS_DETAILS,
                params: { id: rowData.entityId, fromReports: 'true' }
              }
            };
          }
        },
        sortKey: 'classStartDate'
      },
      {
        columnTitle: 'End Date',
        render: 'endDate',
        sortKey: 'classEndDate'
      },
      {
        columnTitle: 'Status',
        render: 'status'
      },
      {
        columnTitle: '',
        render: {
          componentRef: IconLinkComponent,
          componentProps: (
            rowData: InProcessingClassTableData
          ): Record<string, unknown> => {
            return {
              icon: 'pencil',
              displayString: 'Edit',
              linkTo: {
                name: Routes.INPRO_CLASS_EDIT,
                params: { id: rowData.entityId, fromReports: 'true' }
              }
            };
          }
        }
      },
      tableButtons.buildEntityActionsColumn([new DeleteInProActionBuilder()])
    ]
  };
};
