import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { formatDate } from '@/services/formatService';
import { ITableData } from '../models/TableData';

export class InProcessingClassTableData implements ITableData {
  entityId: number;
  isArchived: boolean;
  startDate: string;
  endDate: string;
  status: string;

  constructor(inpro: InProcessingClass) {
    this.entityId = inpro.id;
    this.isArchived = inpro.isArchived;
    this.startDate = formatDate(inpro.classStartDate);
    this.endDate = formatDate(inpro.classEndDate);
    this.status = this.getStatus(inpro);
  }

  getStatus(inPro: InProcessingClass): string {
    const inproStatusArray = [
      inPro.reserveConferenceRoom,
      inPro.lodgingConfirmed,
      inPro.legalConfirmed,
      inPro.trackReserved,
      inPro.scheduleConfirmed,
      inPro.businessCards,
      inPro.tableTents,
      inPro.nameTags,
      inPro.folderLabels,
      inPro.uDriveRequest,
      inPro.uDriveDropOff,
      inPro.shuttleRequest,
      inPro.emailBriefTimes,
      inPro.rosterSenttoComm,
      inPro.rosterSenttoMed,
      inPro.rosterSenttoMed
    ];

    const count = inproStatusArray.filter(
      (value) => value !== undefined && value !== null
    ).length;
    if (count === 0) {
      return 'NEW';
    }
    if (count === inproStatusArray.length) {
      return 'COMPLETE';
    }
    return count + '/' + inproStatusArray.length;
  }
}
