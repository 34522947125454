var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center gap-2" },
    _vm._l(_vm.actions, function (action, index) {
      return _c(
        "button",
        {
          key: index,
          staticClass:
            "flex items-center justify-center flex-grow-0 flex-shrink-0 w-16 focus:outline-none hover:underline",
          class: {
            "opacity-30 cursor-default hover:no-underline": !!action.disabled,
          },
          attrs: { "data-cy": "action-" + index, disabled: action.disabled },
          on: {
            click: function ($event) {
              return _vm.handleClickAction(action)
            },
          },
        },
        [
          _c("icon-component", {
            attrs: {
              name: action.icon,
              fill: action.iconFill,
              stroke: action.iconStroke,
            },
          }),
          _c("div", { staticClass: "ml-1" }, [_vm._v(_vm._s(action.text))]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }