import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { TableDataProvider } from '../models/TableDataProvider';
import { InProcessingClassTableData } from './InProcessingClassTableData';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { SearchInProcessingClassDto } from '@/models/Dtos/searchInProcessingClassDto';

export class InProcessingClassDataProvider extends TableDataProvider<
  InProcessingClassTableData,
  InProcessingClass,
  SearchInProcessingClassDto
> {
  protected transformer = (t: InProcessingClass): InProcessingClassTableData =>
    new InProcessingClassTableData(t);

  protected queryHandler = async (
    searchDto: SearchInProcessingClassDto
  ): Promise<SearchResponseDto<InProcessingClass>> => {
    const results: SearchResponseDto<InProcessingClass> =
      await this.store.dispatch(
        'inProcessingClassModule/fetchWithSelectParameters',
        searchDto
      );
    return results;
  };
}
