
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from './IconComponent.vue';
@Component<IconLinkComponent>({
  components: {
    IconComponent
  }
})
export default class IconLinkComponent extends Vue {
  @Prop()
  displayString?: string;

  @Prop()
  icon!: string;

  @Prop()
  readonly fill?: string;

  @Prop()
  readonly stroke?: string;

  @Prop({ required: true })
  linkTo!: string | Location;
}
