
import IconComponent from '@/components/IconComponent.vue';
import { EntityActionTableComponentModel } from '@/models/Table/EntityActionTableComponentModel';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<EntityActionsTableElementComponent>({
  components: {
    IconComponent
  }
})
export default class EntityActionsTableElementComponent extends Vue {
  @Prop({ required: true })
  readonly data: unknown;
  @Prop({ required: true })
  readonly actions!: EntityActionTableComponentModel[];

  async handleClickAction(
    action: EntityActionTableComponentModel
  ): Promise<void> {
    action.actionHandler();
  }
}
