var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center gap-2" },
    [
      _c(
        "router-link",
        {
          staticClass:
            "flex items-center justify-center flex-grow-0 flex-shrink-0 w-16 focus:outline-none hover:underline",
          attrs: { to: _vm.linkTo },
        },
        [
          _c("icon-component", {
            attrs: { name: _vm.icon, fill: _vm.fill, stroke: _vm.stroke },
          }),
          _c("div", { staticClass: "ml-1" }, [
            _vm._v(_vm._s(_vm.displayString)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }