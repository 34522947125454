
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
@Component<InProcessingClassModalComponent>({
  components: {
    DateSelector,
    Modal,
    TextInput
  }
})
export default class InProcessingClassModalComponent extends Vue {
  @Prop({ default: '' })
  readonly size!: string;
  inProcessingClass: InProcessingClass = new InProcessingClass();
  formState: IFormState = {};
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;
  close(): void {
    this.show = false;
    this.inProcessingClass = new InProcessingClass();
  }
  public open(
    inProcessingClass?: InProcessingClass
  ): Promise<InProcessingClass> {
    this.show = true;
    if (inProcessingClass) {
      this.inProcessingClass = new InProcessingClass(inProcessingClass);
    }
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  done(): void {
    this.resolve(this.inProcessingClass);
    this.close();
  }
  cancel(): void {
    this.reject();
    this.close();
  }
  updateFormState(inputName: string, inputState: FormInputState) {
    this.$set(this.formState, inputName, inputState);
  }
  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1
    );
  }
}
