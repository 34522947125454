import { IEditModal, IModal } from '@/components/Modals/IModal';
import EntityActionsTableElementComponent from '@/components/Table/Elements/EntityActionsTableElementComponent.vue';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { ColumnConfiguration } from '@/models/Table/Table';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { ITableData } from './TableData';

export interface IEntityActionsTableElementComponentProp {
  icon: string;
  text?: string;
  iconFill?: string;
  disabled?: boolean | null;
  actionHandler: () => Promise<void>;
}

export interface IEntityActionOptions<EntityType> {
  editModal?: IEditModal<EntityType>;
  tableDataTypeName?: string;
}

export interface AsyncDataProvider {
  queryData: () => Promise<void>;
  loaded: boolean;
}

export class EntityActionButtons<
  TableDataType extends ITableData,
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> {
  constructor(
    public readonly tableDataTypeName: string,
    public readonly store: Store<RootState>,
    public tableSearchService: AsyncDataProvider,
    public yesNoModal: IModal<boolean>,
    public actionOptions?: IEntityActionOptions<EntityType>
  ) {}

  public buildEntityActionsColumn(
    actionBuilders: {
      build: (
        rowData: TableDataType,
        entityActionButtons: EntityActionButtons<
          TableDataType,
          EntityType,
          SearchDtoType
        >
      ) => IEntityActionsTableElementComponentProp;
    }[]
  ): ColumnConfiguration<TableDataType, unknown, never> {
    return {
      columnTitle: 'Actions',
      render: {
        componentRef: EntityActionsTableElementComponent,
        componentProps: (rowData: TableDataType) => {
          return {
            data: rowData,
            actions: actionBuilders.map((action) => action.build(rowData, this))
          };
        }
      }
    };
  }

  public get capitalizedTableDataTypeName(): string {
    return this.tableDataTypeName.replace(/(^\w|\s\w)/g, (m) =>
      m.toUpperCase()
    );
  }
}
