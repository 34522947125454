import { SearchDto } from '@/models/Dtos/common/searchDto';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../models/EntityActionButtons';
import { ITableData } from '../models/TableData';
import { IEntityActionBuilder } from './EntityAction';

export abstract class DeleteEntityActionBuilder<
  TableDataType extends ITableData,
  EntityType,
  SearchDtoType extends SearchDto<EntityType, unknown>
> implements IEntityActionBuilder<TableDataType, EntityType, SearchDtoType>
{
  public abstract deleteString: 'delete' | 'archive';
  protected abstract delete: (
    data: TableDataType,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      EntityType,
      SearchDtoType
    >
  ) => Promise<void>;

  build(
    rowData: TableDataType,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      EntityType,
      SearchDtoType
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'trash',
      text: 'Archive',
      iconFill: '#C81111',
      disabled: rowData.isArchived,
      actionHandler: async () => {
        const confirmed = await entityActionButtons.yesNoModal.open({
          title: `${this.capitilizedDeleteString} ${entityActionButtons.capitalizedTableDataTypeName}?`,
          message:
            `Are you sure you want to ${this.deleteString} this ` +
            entityActionButtons.tableDataTypeName +
            '?'
        });
        if (!confirmed) {
          return;
        }
        entityActionButtons.tableSearchService.loaded = false;
        try {
          await this.delete(rowData, entityActionButtons);
          return entityActionButtons.tableSearchService.queryData();
        } catch (err) {
          if (err) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `The ${entityActionButtons.tableDataTypeName} failed to ${this.deleteString}.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }

  get capitilizedDeleteString(): string {
    return this.deleteString.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  }
}
