
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import Routes from '@/router/Routes';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { SearchInProcessingClassDto } from '@/models/Dtos/searchInProcessingClassDto';
import { InProcessingClassDataProvider } from '@/components/Table/InprocessingClass/InProcessingClassDataProvider';
import { InProcessingClassTableData } from '@/components/Table/InprocessingClass/InProcessingClassTableData';
import { ReportInProcessingClassTableConfigBuilder } from '@/components/Table/InprocessingClass/ReportInProcessingClassTableConfigBuilder';
import { StudentInProcessingClassTableConfigBuilder } from '@/components/Table/InprocessingClass/StudentInProcessingClassTableConfigBuilder';
import InProcessingClassModalComponent from '@/components/Modals/InProcessingClass/InProcessingClassModalComponent.vue';

@Component<InProcessingClassPage>({
  components: {
    ButtonComponent,
    IconComponent,
    PageLayout,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    InProcessingClassModalComponent
  }
})
export default class InProcessingClassPage extends Vue {
  fromReports = 'false';

  setLocation(): void {
    if (this.$route.params.fromReports)
      this.fromReports = this.$route.params.fromReports;
  }

  get pathing(): Routes {
    return this.fromReports === 'true'
      ? Routes.REPORTS_DASHBOARD
      : Routes.STUDENT_DASHBOARD;
  }

  get naming(): string {
    return this.fromReports === 'true' ? 'Reports /' : '340th Dashboard /';
  }

  searchService: TableSearchService<
    InProcessingClassTableData,
    InProcessingClass,
    never,
    SearchInProcessingClassDto
  > | null = null;

  async created(): Promise<void> {
    this.setLocation();
    this.searchService = new TableSearchService<
      InProcessingClassTableData,
      InProcessingClass,
      never,
      SearchInProcessingClassDto
    >(
      this.$store,
      new InProcessingClassDataProvider(this.$store),
      {},
      { defaultSortProperty: 'classStartDate' },
      this.fromReports == 'true'
        ? ReportInProcessingClassTableConfigBuilder
        : StudentInProcessingClassTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
  }

  $refs!: {
    InProClassModal: InProcessingClassModalComponent;
  };

  async createClass(): Promise<void> {
    try {
      if (!this.searchService) {
        return;
      }
      const inProClass = await this.$refs.InProClassModal.open(undefined);
      await this.$store.dispatch('inProcessingClassModule/create', inProClass);
      this.showSuccess('Class created');
    } catch (err: unknown) {
      this.showError('An error occurred while creating the class');
    }
  }

  showSuccess(message: string): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'checkGreen'
    });
  }
  showError(message = 'An error occurred'): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'caution'
    });
  }
}
